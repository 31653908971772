import { graphql } from "gatsby";
import React from "react";

export default function About({ data }) {
  return <></>;
}

export const query = graphql`
  query {
    queryAbout: allSanityAbout(filter: { language: { eq: "en" } }) {
      nodes {
        map {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        openingInfos {
          _rawText(resolveReferences: { maxDepth: 10 })
          title
          manuscript {
            asset {
              url
            }
          }
        }

        id
        title
        _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
    queryKontakt: allSanityContact(filter: { language: { eq: "en" } }) {
      nodes {
        id
        reasonsList
        openingInfos {
          _rawText(resolveReferences: { maxDepth: 10 })
          title
          manuscript {
            asset {
              url
            }
          }
        }

        _rawText
        title
      }
    }
    queryHistory: allSanityHistory(filter: { language: { eq: "en" } }) {
      nodes {
        _rawText
        images {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        title
        id
      }
    }
    seo: allSanitySeo(
      filter: { title: { eq: "Info/Kontakt" }, language: { eq: "en" } }
    ) {
      nodes {
        id
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
              base64
            }
          }
        }
        title
        titlePage
        description
      }
    }
  }
`;
